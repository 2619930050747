import React from "react"
// import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import HeaderLine from "../components/HeaderLine"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { GoMail } from "react-icons/Go"
import { GoLocation } from "react-icons/Go"
import { ImPhone } from "react-icons/Im"

const contacts = ({ pageContext, location }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // Example of dynamically using location prop as a crumbLabel
  // NOTE: this code will not work for every use case, and is only an example
  // const customCrumbLabel = location.pathname.toLowerCase().replace("-", " ")

  return (
    // <Layout curmenu={location.pathname}>
    <Layout>
      <HeaderLine />

      <div className="container mx-auto max-w-screen-xl py-8 pb-2 container-pad">
        {/* <Breadcrumb location={location} crumbLabel="КОНТАКТЫ" /> */}
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel="Контакты"
        />
      </div>

      <div className="container mx-auto max-w-screen-xl flex-none text-center text-3xl sm:text-5xl leading-none py-4 pb-8 container-pad">
        КОНТАКТЫ
      </div>

      <div className="container mx-auto max-w-screen-xl py-2 pb-8 container-pad">
        <p className="py-3 leading-5">
          <ImPhone className="inline text-2xl mr-5" />
          <a href="tel:+74955455650" className="hover:text-blue-600">
            +7 (495) 545-5650
          </a>
        </p>
        <p className="py-3 leading-5">
          <GoMail className="inline text-2xl mr-5" />
          <a
            href="mailto:rishon@rishon.ru"
            className="hover:text-blue-600 footer__mail"
          >
            rishon@rishon.ru
          </a>
        </p>

        <div className="py-3 leading-5 flex items-center">
          <GoLocation className="inline text-2xl mr-5" />
          <span>
            Адрес для корреспонденции: <br />
            119313, г.Москва, Ленинский проспект, д. 95, этаж цокольный, пом. X,
            офис 19
          </span>
        </div>
      </div>
      {/* <SimpleSlider /> */}
      {/* <Experience /> */}
      {/* <OurProjects /> */}
      {/* <Hero /> */}
      {/* <Services /> */}
      <Footer />
    </Layout>
  )
}

export default contacts

// ...GatsbyImageSharpFluid
